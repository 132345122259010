import { sendInAppKycOtpApi } from "../../../apis/coApplicant";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { AADHAR_VERIFICATION_FAILED } from "../constant";
import { isJsonString } from "../../../utils/misc";

const useGetAadharDetails = () => {
    const navigate = useNavigate();

    const { state } = useLocation();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async ({ aadharNumber }: { aadharNumber: string }) => {
        try {
            setLoading(true);
            const aadharNo = aadharNumber?.split(" ")?.join("")?.trim();

            const response = await sendInAppKycOtpApi({
                aadhaarNo: aadharNo,
            });

            if (response?.success) {
                navigate("/aadhar-details-verify", {
                    state: {
                        aadharNumber: aadharNumber,
                        token: state?.token,
                    },
                });
            }
        } catch (error: any) {
            if (isJsonString(error?.response?.data?.message)) {
                const errorObj = JSON.parse(error?.response?.data?.message);

                if (Number(errorObj?.code) === 15001) {
                    toast.error(errorObj?.message);
                } else {
                    toast.error(AADHAR_VERIFICATION_FAILED);
                }

                navigate("/verify-details", {
                    state: { token: state?.token },
                });
            } else {
                toast.error("Something went wrong!");
            }
        } finally {
            setLoading(false);
        }
    };

    return {
        handleSubmit,
        loading,
    };
};

export default useGetAadharDetails;
