import { IOtpFormik } from "./types";
import { OtpInput } from "./components";

interface Props {
    formikProps: IOtpFormik["formikProps"];
}

const OtpForm = ({ formikProps }: Props) => {
    return (
        <div style={{ display: "flex", gap: "8px" }}>
            {formikProps?.values.otp.map((_, index) => {
                const touchedField = (formikProps?.touched as any)?.otp?.[
                    index
                ];

                return (
                    <OtpInput
                        key={`otp[${index}]`}
                        touchedField={touchedField}
                        formikProps={formikProps}
                        index={index}
                    />
                );
            })}
        </div>
    );
};

export default OtpForm;
