import { useState, useEffect, useCallback } from "react";

interface UseResendOtpParams {
    onResend: () => Promise<void>;
}

const useResendOtp = ({ onResend }: UseResendOtpParams) => {
    const [remainingTime, setRemainingTime] = useState(60);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else {
            setIsDisabled(false);
        }
    }, [remainingTime]);

    const handleResend = useCallback(async () => {
        setIsDisabled(true);
        setRemainingTime(60);
        try {
            await onResend();
        } catch (error) {
            console.error("Error resending OTP:", error);
        }
    }, [onResend]);

    return { remainingTime, isDisabled, handleResend };
};

export default useResendOtp;
