import React, { useEffect, useState } from "react";
import {
    acceptKycDeclarationApi,
    getKycDeclarationApi,
} from "../../../apis/coApplicant";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";

const useGetInAppKyc = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [kycDeclaration, setKycDeclaration] = useState<string>("");

    const fetchApi = async () => {
        try {
            const response = await getKycDeclarationApi();

            if (response?.success) {
                setKycDeclaration(response?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const acceptKycDeclaration = async () => {
        try {
            const response = await acceptKycDeclarationApi();

            if (response?.success) {
                navigate("/aadhar-details", {
                    state: { token: state?.token },
                });
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong!");
        }
    };

    useEffect(() => {
        fetchApi();
    }, []);

    return { kycDeclaration, acceptKycDeclaration };
};

export default useGetInAppKyc;
