export const isFile = (input: File | Object) => {
    if ("File" in window && input instanceof File) return true;
    else return false;
};

export const convertJsonToFormData = (
    obj: any,
    formData: FormData,
    formedKey: string
) => {
    const checkIfDotApplies = (formedKey: string, key: string) => {
        return formedKey.length == 0 ? `${key}` : `.${key}`;
    };
    if (!obj) return;
    for (let key of Object.keys(obj)) {
        if (key === "form60" && isFile(obj?.[key]) === false) continue;

        if (obj?.[key] === undefined) continue;

        if (typeof obj?.[key] !== "object") {
            formData.append(
                formedKey + `${checkIfDotApplies(formedKey, key)}`,
                obj[key]
            );
        } else if (Array.isArray(obj?.[key])) {
            for (let [i, item] of obj[key].entries()) {
                convertJsonToFormData(
                    item,
                    formData,
                    formedKey +
                        `${checkIfDotApplies(formedKey, key + `[${i}]`)}`
                );
            }
        } else {
            convertJsonToFormData(
                obj?.[key],
                formData,
                formedKey + `${checkIfDotApplies(formedKey, key)}`
            );
        }
    }
};

export const numberWithCommas = (x: number) => {
    if (x) {
        let parts = x.toString().split(".");
        let lastThree = parts[0].substring(parts[0].length - 3);
        let otherNumbers = parts[0].substring(0, parts[0].length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        parts[0] =
            otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return parts.join(".");
    }
    return 0;
};

export const numberToWordIndianFormat = (price: any) => {
    var sglDigit = [
            "Zero",
            "One",
            "Two",
            "Three",
            "Four",
            "Five",
            "Six",
            "Seven",
            "Eight",
            "Nine",
        ],
        dblDigit = [
            "Ten",
            "Eleven",
            "Twelve",
            "Thirteen",
            "Fourteen",
            "Fifteen",
            "Sixteen",
            "Seventeen",
            "Eighteen",
            "Nineteen",
        ],
        tensPlace = [
            "",
            "Ten",
            "Twenty",
            "Thirty",
            "Forty",
            "Fifty",
            "Sixty",
            "Seventy",
            "Eighty",
            "Ninety",
        ],
        handle_tens = function (dgt: any, prevDgt: any) {
            return 0 == dgt
                ? ""
                : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
        },
        handle_utlc = function (dgt: any, nxtDgt: any, denom: any) {
            return (
                (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
                (0 != nxtDgt || dgt > 0 ? " " + denom : "")
            );
        };

    var str = "",
        digitIdx = 0,
        digit = 0,
        nxtDigit = 0,
        words: any = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
        for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
            switch (
                ((digit = price[digitIdx] - 0),
                (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
                price.length - digitIdx - 1)
            ) {
                case 0:
                    words.push(handle_utlc(digit, nxtDigit, ""));
                    break;
                case 1:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 2:
                    words.push(
                        0 != digit
                            ? " " +
                                  sglDigit[digit] +
                                  " Hundred" +
                                  (0 != price[digitIdx + 1] &&
                                  0 != price[digitIdx + 2]
                                      ? " and"
                                      : "")
                            : ""
                    );
                    break;
                case 3:
                    words.push(handle_utlc(digit, nxtDigit, "Thousand"));
                    break;
                case 4:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 5:
                    words.push(handle_utlc(digit, nxtDigit, "Lakh"));
                    break;
                case 6:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 7:
                    words.push(handle_utlc(digit, nxtDigit, "Crore"));
                    break;
                case 8:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 9:
                    words.push(
                        0 != digit
                            ? " " +
                                  sglDigit[digit] +
                                  " Hundred" +
                                  (0 != price[digitIdx + 1] ||
                                  0 != price[digitIdx + 2]
                                      ? " and"
                                      : " Crore")
                            : ""
                    );
            }
        str = words.reverse().join("");
    } else str = "";
    return str.length > 0 ? str.trim() + " Only" : "";
};

export const formatAadhaar = (value: string = "") => {
    return (
        value
            ?.replace(/\s/g, "")
            ?.match(/.{1,4}/g)
            ?.join(" ") || ""
    );
};

export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
