import React, { useEffect } from "react";
import { IOtpFormik } from "../../types";
import Input from "../../../../../../components/CustomInput";

interface Props {
    index: number;
    formikProps: IOtpFormik["formikProps"];
    touchedField: any;
}

export const OtpInput = ({ index, formikProps, touchedField }: Props) => {
    const { values, setFieldValue, setFieldTouched } = formikProps;

    useEffect(() => {
        const values = formikProps?.values;
        const otpArray = [];

        for (let i = 0; i < 6; i++) {
            if (values?.otp?.[i]) {
                otpArray.push(undefined);
            } else {
                otpArray.push("Required");
            }
        }

        formikProps?.setErrors({
            otp: otpArray as string[],
        });
    }, [formikProps?.values?.otp, formikProps?.errors?.otp]);

    return (
        <div key={index}>
            <Input
                name={`otp[${index}]`}
                type="tel"
                maxLength={1}
                inputClassName={`text-center`}
                errorMessageRequired={false}
                onChange={(value: string) => {
                    const otpValue = value?.replace(/\D/, "");

                    setFieldValue(`otp[${index}]`, otpValue);

                    if (otpValue && index < values.otp.length - 1) {
                        const nextField = document.querySelector(
                            `input[name="otp[${index + 1}]"]`
                        ) as HTMLInputElement;
                        nextField?.focus();
                    }
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldTouched(`otp[${index}]`, true);
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (
                        e.key === "Backspace" &&
                        !values.otp[index] &&
                        index > 0
                    ) {
                        const prevField = document.querySelector(
                            `input[name="otp[${index - 1}]"]`
                        ) as HTMLInputElement;
                        prevField?.focus();
                    }
                }}
            />
        </div>
    );
};
