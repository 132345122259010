import React from "react";
import MainContainer from "../MainContainer";
import Button from "../../components/Button";
import { HEADER, I_ACCEPT_TEXT } from "./constants";
import { useLocation, useNavigate } from "react-router";
import Header from "../../components/Header";
import useGetInAppKyc from "./hooks/useGetInAppKyc";

const InAppKyc = () => {
    const navigate = useNavigate();

    const { state } = useLocation();

    const { kycDeclaration, acceptKycDeclaration } = useGetInAppKyc();

    return (
        <MainContainer>
            <div className="p-4 flex flex-col relative w-full">
                <div className="h-[calc(100vh-140px)] overflow-y-auto">
                    <Header
                        title={HEADER}
                        onBack={() => navigate("/verify-details")}
                    />
                    <div className="mt-4" />
                    <span className="text-neutral-3 mt-2 text-left">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: kycDeclaration,
                            }}
                        ></div>
                    </span>
                </div>
                <div
                    className={
                        "fixed bottom-0 bg-white w-full gap-x-2 p-3 max-w-3xl mx-auto left-1/2 translate-x-[-50%] cursor-pointer"
                    }
                >
                    <Button
                        text={I_ACCEPT_TEXT}
                        type="primary"
                        onClick={async () => acceptKycDeclaration()}
                    />
                </div>
            </div>
        </MainContainer>
    );
};

export default InAppKyc;
