import { useContext } from "react";
import MainContainer from "../MainContainer";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router";
import { setCookie } from "../../utils/cookies";
import { MainContext } from "../../routes";
import { numberToWordIndianFormat, numberWithCommas } from "../../utils/misc";

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get("token");
    const { coApplicantDetails, setCoApplicantDetails } =
        useContext(MainContext);

    if (paramValue) {
        setCookie(`${process.env.REACT_APP_SESSION_COOKIE_NAME}`, paramValue);
    }

    return (
        <MainContainer>
            <div className="p-4 flex flex-col items-center relative overflow-y-auto h-[100vh-100px]">
                <div className="text-xl mb-2 ">
                    Get Instant Solar Loans with
                </div>
                <div>
                    <img src="/assets/icons/aerem-logo.svg" alt="aerem-logo" />
                </div>
                <div className="bg-[#FFFAEA] rounded-xl border-neutral-6 relative mt-14 p-4 pt-8 text-center">
                    <img
                        src="/assets/icons/user-icon.svg"
                        alt="aerem-logo"
                        className="absolute -top-5 left-2/4 -translate-x-2/4"
                    />
                    <div>
                        Hello{" "}
                        <span className="font-medium">
                            {coApplicantDetails?.aadharDetails
                                ? coApplicantDetails?.aadhaarName
                                : coApplicantDetails?.coApplicantName}
                        </span>
                    </div>{" "}
                    {coApplicantDetails?.isMainApplicant ? (
                        <div>
                            Please complete your KYC to process your loan
                            application.
                        </div>
                    ) : (
                        <div>
                            Please complete your KYC to process your loan
                            application as an applicant for the solar loan on
                            behalf of{" "}
                            <span className={`font-medium`}>
                                {coApplicantDetails?.companyName ||
                                    coApplicantDetails?.maainApplicantName
                                        ?.name}
                            </span>
                            .
                        </div>
                    )}
                    <div className="font-medium mt-2 text-base">
                        ₹{" "}
                        {numberWithCommas(
                            Number(coApplicantDetails?.loanAmount || 0)
                        )}
                    </div>
                    <div>
                        (₹{" "}
                        {numberToWordIndianFormat(
                            Number(coApplicantDetails?.loanAmount || 0)
                        )}
                        )
                    </div>
                </div>
                <div className="font-medium mt-10 text-center">
                    Verify your details to proceed with the Application
                </div>
                <div
                    className={
                        "fixed bottom-0 bg-white w-full gap-x-2 p-3 max-w-3xl mx-auto left-1/2 translate-x-[-50%]"
                    }
                >
                    <Button
                        text={"Verify Details"}
                        type="primary"
                        onClick={() =>
                            navigate(`/verify-details`, {
                                state: { token: paramValue || "" },
                            })
                        }
                    />
                </div>
            </div>
        </MainContainer>
    );
};

export default Home;
