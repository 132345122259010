import React from "react";
import useResendOtp from "./hooks/useResendOtp";

interface Props {
    onResend: () => Promise<void>;
}

export const ResendOtp = ({ onResend }: Props) => {
    const { remainingTime, isDisabled, handleResend } = useResendOtp({
        onResend,
    });

    const renderTimer = () => {
        if (remainingTime === 60) {
            return `01:00`;
        }

        if (remainingTime < 10) {
            return `00:0${remainingTime}`;
        }

        return `00:${remainingTime}`;
    };

    return (
        <div className={`mt-4 text-center`}>
            <p
                onClick={isDisabled ? () => {} : () => handleResend()}
                className={`text-xs font-[600] ${isDisabled ? "cursor-not-allowed text-[#BABABA]" : "cursor-pointer text-primary"}`}
            >
                {isDisabled ? `Resend OTP in ${renderTimer()}` : "Resend OTP"}
            </p>
        </div>
    );
};
