import * as Yup from "yup";

export const HEADER = "Verify OTP";
export const DESC =
    "Kindly enter the OTP sent to your Aadhaar-registered mobile number for verification.";
export const VERIFY_OTP_TEXT = "VERIFY OTP";

export const AADHAR_NUMBER_LABEL = "Aadhaar Number";
export const OTP_LABEL = "OTP";

export const LOADING_TEXT = `Please hold on, we're processing your request...`;

export const VERIFY_OTP_ERROR_MESSAGE = "Aadhaar verification failed.";
export const PAN_AND_AADHAR_ERROR_MESSAGE =
    "PAN and Aadhaar names do not match.";

export const validationSchema = Yup.object().shape({
    otp: Yup.array()
        .of(
            Yup.string()
                .matches(/^\d$/, "Only digits are allowed")
                .required("Required")
        )
        .min(6, "OTP must be 6 digits")
        .max(6, "OTP must be 6 digits"),
});
