import React from "react";
import { Spinner } from "../Spinner";

interface Props {
    message: string;
    messageClassName?: string;
}

const ScreenLoader = ({ message, messageClassName }: Props) => {
    return (
        <div
            className={
                "fixed top-0 left-0 w-full h-full bg-[rgba(180,180,180,0.7)] z-[99999] flex items-center justify-center"
            }
        >
            <div className={`overflow-auto flex flex-col items-center`}>
                <Spinner size="lg" />
                <div className={`mt-5 text-center ${messageClassName}`}>
                    {message}
                </div>
            </div>
        </div>
    );
};

export default ScreenLoader;
