import { createContext } from "react";
import MainContainer from "../MainContainer";
import Header from "../../components/Header";
import { useNavigate } from "react-router";
import Button from "../../components/Button";
import {
    AADHAR_FORM_NUMBER,
    AADHAR_FORM_PLACEHOLDER,
    DESC,
    HEADER,
    LOADING_TEXT,
    MANDATORY_FOR_LOAN_APPLICATION_TEXT,
    MAX_LENGTH,
    SEND_OTP_TEXT,
    YOUR_AADHAR_TEXT,
    validationSchema,
} from "./constant";
import Input from "../../components/CustomInput";
import { Formik } from "formik";
import { IAadharDetailsForm } from "./types";
import { formatAadhaar } from "../../utils/misc";
import useGetAadharDetails from "./hooks/useGetAadharDetails";
import ScreenLoader from "../../components/ScreenLoader";

export const AadhaarDetailsContext = createContext<any>("lll");

const AadharDetails = () => {
    const navigate = useNavigate();

    const { handleSubmit, loading } = useGetAadharDetails();

    return (
        <MainContainer>
            <Formik
                initialValues={{
                    aadharNumber: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    await handleSubmit(values);
                }}
            >
                {(formikProps: IAadharDetailsForm["formikProps"]) => (
                    <div className="p-4 flex flex-col relative w-full">
                        <div className="h-[calc(100vh-140px)] overflow-y-auto">
                            <Header
                                title={HEADER}
                                onBack={() => navigate("/verify-details")}
                            />
                            <div className={`flex flex-col items-center`}>
                                <span className="text-neutral-3 text-left mt-4">
                                    {DESC}
                                </span>
                                <div className={`mt-8`}>
                                    <img
                                        src="/assets/icons/pan-img.svg"
                                        alt="right-arrow-icon"
                                    />
                                </div>
                                <div className={`mt-3 text-[1rem] font-[500]`}>
                                    {YOUR_AADHAR_TEXT}
                                </div>
                                <div className={`mt-2 text-xs`}>
                                    {MANDATORY_FOR_LOAN_APPLICATION_TEXT}
                                </div>
                            </div>
                            <Input
                                name={AADHAR_FORM_NUMBER}
                                placeholder={AADHAR_FORM_PLACEHOLDER}
                                inputClassName={"w-[100%] text-neutral-4"}
                                onChange={(value: string) => {
                                    const formattedValue = formatAadhaar(
                                        value || ""
                                    );

                                    formikProps?.setFieldValue(
                                        AADHAR_FORM_NUMBER,
                                        formattedValue
                                    );
                                }}
                                onBlur={() => {
                                    formikProps?.setFieldTouched(
                                        AADHAR_FORM_NUMBER,
                                        true
                                    );
                                }}
                                maxLength={MAX_LENGTH}
                            />
                        </div>
                        <div
                            className={
                                "fixed bottom-0 bg-white w-full gap-x-2 p-3 max-w-3xl mx-auto left-1/2 translate-x-[-50%] cursor-pointer"
                            }
                        >
                            <Button
                                text={SEND_OTP_TEXT}
                                type="primary"
                                onClick={async () => {
                                    formikProps?.handleSubmit();
                                }}
                                disabled={
                                    !formikProps?.values?.aadharNumber ||
                                    !!formikProps?.errors?.aadharNumber ||
                                    loading
                                }
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </MainContainer>
    );
};

export default AadharDetails;
