import React, { useRef } from "react";
import getMappings from "./utils/getMappings";
import { IAadharStateMapping } from "./types";
import { useLocation, useNavigate } from "react-router";
import useOutsideClick from "../../../../hooks/useOutsideClick";

interface Props {
    onClose: () => void;
    openDigiLockerPopup: () => Promise<void>;
}

export const AadharStateModal = ({
    onClose = () => {},
    openDigiLockerPopup,
}: Props) => {
    const navigate = useNavigate();

    const { state } = useLocation();

    const modalRef = useRef<any>(null);

    const mappings = getMappings({
        openDigiLockerPopup,
        redirectToInAppKyc: async () =>
            navigate("/in-app-kyc", { state: { token: state?.token } }),
    });

    useOutsideClick(modalRef, onClose);

    return (
        <div
            className={
                "fixed top-0 left-0 w-full h-full bg-[rgba(180,180,180,0.7)] z-[99999] flex items-center justify-center"
            }
        >
            <div
                className={`bg-white w-[560px] overflow-auto border rounded-xl mx-5`}
                ref={modalRef}
            >
                <div className="flex justify-between px-4 p-3">
                    <div className={`text-[0.9rem] font-[500]`}>
                        Choose Your KYC Method
                    </div>
                    <button className={`text-[32px]`} onClick={() => onClose()}>
                        &times;
                    </button>
                </div>
                <div className={`p-[20px]`}>
                    {(mappings || []).map(
                        (item: IAadharStateMapping, index: number) => {
                            const { id, label, description, action } = item;

                            return (
                                <div
                                    id={id}
                                    className={`flex justify-between items-center border-[0.09rem] rounded-[0.4rem] cursor-pointer hover:bg-gray-100 transition-all duration-[0.1s] ${index === mappings.length - 1 ? "" : "mb-4"} p-3`}
                                    onClick={action}
                                >
                                    <div>
                                        <div
                                            className={`text-[1rem] font-[500]`}
                                        >
                                            {label}
                                        </div>
                                        <div>{description}</div>
                                    </div>
                                    <img
                                        src="/assets/icons/right-arrow-icon.svg"
                                        alt="right-arrow-icon"
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};
