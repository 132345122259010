import { UPLOAD_AADHAR_STATE } from "../../../constant";
import {
    DIGILOCKER_KYC_DESC,
    DIGILOCKER_KYC_LABEL,
    IN_APP_KYC_DESC,
    IN_APP_KYC_LABEL,
} from "../constants";
import { IAadharStateMapping } from "../types";

const getMappings = ({
    openDigiLockerPopup,
    redirectToInAppKyc,
}: {
    openDigiLockerPopup: () => Promise<void>;
    redirectToInAppKyc: () => Promise<void>;
}) => {
    const MAPPINGS: IAadharStateMapping[] = [
        {
            id: UPLOAD_AADHAR_STATE.DIGILOCKER_KYC,
            label: DIGILOCKER_KYC_LABEL,
            description: DIGILOCKER_KYC_DESC,
            action: () => openDigiLockerPopup(),
        },
        {
            id: UPLOAD_AADHAR_STATE.IN_APP_KYC,
            label: IN_APP_KYC_LABEL,
            description: IN_APP_KYC_DESC,
            action: async () => redirectToInAppKyc(),
        },
    ];

    return MAPPINGS;
};

export default getMappings;
