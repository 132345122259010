import React from "react";

interface SpinnerProps {
    size?: "sm" | "md" | "lg";
    label?: string;
    className?: string;
    color?: string;
}

const sizeClasses = {
    sm: "w-4 h-4 border-2",
    md: "w-10 h-10 border-4",
    lg: "w-12 h-12 border-4",
};

const colorClasses = {
    default: "border-primary",
    white: "border-blue-white",
    grey: "border-grey-grey-300",
};

export const Spinner: React.FC<SpinnerProps> = ({
    size = "md",
    label,
    className = "",
    color = "default",
}) => {
    return (
        <div className={`flex flex-col items-center ${className}`}>
            <div
                className={`
          ${sizeClasses[size]} 
          ${colorClasses[color as keyof typeof colorClasses]}
          border-t-transparent 
          rounded-full 
          animate-spin
        `}
            ></div>
            {label && (
                <span className={`mt-2 text-sm text-grey-grey-500`}>
                    {label}
                </span>
            )}
        </div>
    );
};
