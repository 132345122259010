import {
    ISendInAppKycOtpPayload,
    IVerifyInAppKycOtpPayload,
} from "../containers/AadharDetails/types";
import httpClient from "../utils/httpClient";
import { convertJsonToFormData } from "../utils/misc";
import { toastTypes } from "../utils/reusableConst";
import { showToast } from "../utils/toast";

export const getScanPanCardDetails = async (payload: any) => {
    try {
        const {
            data: { data: response },
        } = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/getPanScanInfo`,
            data: payload,
        });
        return response;
    } catch (e) {
        return null;
    }
};

export const getPanCardDetails = async (panNo: any) => {
    try {
        const {
            data: { data: response },
        } = await httpClient({
            method: "GET",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/getPANInfo?panNo=${panNo}`,
        });
        return response;
    } catch (e) {
        return null;
    }
};

export const getCoApplicantDetails = async () => {
    try {
        const { data } = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/coApplicants/getWelcomeDashboard`,
        });
        return data;
    } catch (e) {
        return null;
    }
};

export const savePanCardDetails = async (payload: any) => {
    try {
        return await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/coApplicants/panDetails`,
            data: payload,
        });
    } catch (e) {
        return e;
    }
};

export const saveAadharCardDetails = async (payload: any) => {
    try {
        return await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/coApplicants/aadhaarDetails`,
            data: payload,
        });
    } catch (e) {
        return e;
    }
};

export const saveLivenessDetails = async (payload: any) => {
    try {
        const { data } = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/coApplicants/updateAlivenessDetails`,
            data: payload,
        });
        return data;
    } catch (e) {
        return null;
    }
};

export const getScanAadhaarCardDetails = async (payload: any) => {
    try {
        const {
            data: { data: response },
        } = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/getAadhaarScanInfo`,
            data: payload,
        });
        return response;
    } catch {
        return null;
    }
};

export const getAadhaarCardDetails = async (aadhaarNo: string) => {
    try {
        const {
            data: { data: response },
        } = await httpClient({
            method: "GET",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/aadhaarLiteInfo?aadhaarNo=${aadhaarNo}`,
        });
        return response;
    } catch {
        return null;
    }
};

export const getUserDetails = async (payload: any) => {
    try {
        const {
            data: { data: response },
        } = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/getLivenessInfo`,
            data: payload,
        });
        return response;
    } catch {
        return null;
    }
};

export const confirmCoApplicantKycDetails = async () => {
    try {
        const { data } = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/coApplicants/confirmation`,
        });
        return data;
    } catch (e) {
        return null;
    }
};

export const getAllCoApplicants = async () => {
    try {
        const response = await httpClient({
            method: "GET",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/coApplicants/getAllCoApplicants`,
        });

        return response;
    } catch (error) {
        return null;
    }
};

export const getCoApplicantDocs = async ({
    loanApplicantId,
}: {
    loanApplicantId: string;
}) => {
    try {
        const response = await httpClient({
            method: "GET",
            url: `${
                process.env.REACT_APP_API_ENDPOINT
            }/customer/loan/coApplicants/docs?${
                loanApplicantId ? `loanApplicantId=${loanApplicantId}` : ""
            }`,
        });

        return response;
    } catch (error) {
        console.log(error);
    }
};

export const uploadCoApplicantDocuments = async (data: any) => {
    try {
        const formData = new FormData();

        convertJsonToFormData(data, formData, "");

        for (let i = 0; i < data?.files?.length; i++) {
            formData.append(`file`, data?.files?.[i]);
        }

        const response = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/coApplicants/docs`,
            data: formData,
        });

        return response;
    } catch (error) {
        console.log(error);
    }
};

export const viewDocument = async (data: any) => {
    try {
        const response = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/tasks/doc`,
            data,
        });

        return response;
    } catch (error) {
        console.log(error);
    }
};

export const deleteCoApplicantDocument = async (data: any) => {
    try {
        const response = await httpClient({
            method: "DELETE",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/coApplicants/deleteDoc`,
            data,
        });

        return response;
    } catch (error) {
        console.log(error);
    }
};

export const initiateDigiLockerKyc = async () => {
    try {
        const response = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/digilocker`,
        });
        return response;
    } catch (error: any) {
        console.log(error);
        let errorMessage = error?.response?.data?.message;
        showToast(
            (typeof errorMessage === "string" &&
                JSON.parse(errorMessage).message) ||
                "",
            toastTypes.error
        );
    }
};

function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
export const digiLockerKycStatus = async () => {
    try {
        let digiLockerStatusCountCheck = 0;
        let response;
        while (digiLockerStatusCountCheck < 5) {
            response = await httpClient({
                method: "GET",
                url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/digilocker/kyc-status`,
            });

            if (response?.data?.success) {
                if (response?.data?.data?.status !== "PENDING") {
                    break;
                } else {
                    await delay(5000); // Waits for 5 seconds
                    digiLockerStatusCountCheck++;
                }
            }
        }
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const compareFace = async (payload: any) => {
    const { body } = payload;
    try {
        const response = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/digilocker/compare-pic`,
            data: body,
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const sendInAppKycOtpApi = async ({
    aadhaarNo,
}: ISendInAppKycOtpPayload) => {
    try {
        const response = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/aadhaar-kyc/send-otp`,
            data: {
                aadhaarNo,
            },
        });

        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const verifyInAppKycOtpApi = async ({
    aadhaarNo,
    otp,
}: IVerifyInAppKycOtpPayload) => {
    try {
        const response = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/aadhaar-kyc/verify-otp`,
            data: {
                aadhaarNo,
                otp,
            },
        });

        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getKycDeclarationApi = async () => {
    try {
        const response = await httpClient({
            method: "GET",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/aadhaar-kyc/declaration`,
        });

        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const acceptKycDeclarationApi = async () => {
    try {
        const response = await httpClient({
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}/customer/loan/aadhaar-kyc/consent`,
        });

        return response?.data;
    } catch (error) {
        throw error;
    }
};
