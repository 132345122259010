import React from "react";

interface Props {
    title: string;
    onBack: () => void;
}

const Header = ({ title, onBack = () => {} }: Props) => {
    return (
        <div className="flex flex-col items-center w-full">
            <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-x-2">
                    <div onClick={onBack}>
                        <img
                            src="/assets/icons/back-arrow-icon.svg"
                            alt="back-arrow-icon"
                        />
                    </div>
                    <div className="text-lg font-semibold">{title}</div>
                </div>
                <div>
                    <img
                        src="/assets/icons/enquiry-icon.svg"
                        alt="enquiry-icon"
                    />
                </div>
            </div>
        </div>
    );
};

export default Header;
