import * as Yup from "yup";

export const HEADER = "Aadhar Details";
export const DESC =
    "Enter your Aadhaar details to streamline loan approvals. An OTP will be sent for verification.";
export const YOUR_AADHAR_TEXT = "Your Aadhaar";
export const MANDATORY_FOR_LOAN_APPLICATION_TEXT =
    "Mandatory for Loan Application";
export const SEND_OTP_TEXT = "SEND OTP";

export const AADHAR_FORM_PLACEHOLDER = "Enter your aadhaar number...";
export const AADHAR_FORM_NUMBER = "aadharNumber";
export const AADHAR_FORM_STATE = "aadharState";

export const LOADING_TEXT = `Please hold on, we're processing your request...`;

export const MAX_LENGTH = 14;

export const AADHAR_VERIFICATION_FAILED = "Aadhaar verification failed.";

export const validationSchema = Yup.object({
    aadharNumber: Yup.string()
        .matches(
            /^\d{4} \d{4} \d{4}$/,
            "Invalid Aadhaar number. Please ensure it follows the format 'XXXX XXXX XXXX' and contains only digits (0-9)."
        )
        .required("Aadhaar number is required")
        .test(
            "valid-aadhaar",
            "Aadhaar number must contain exactly 12 digits",
            (value) => {
                if (!value) return false; // Ensure value exists
                const strippedValue = value.replace(/\s/g, ""); // Remove spaces
                return /^\d{12}$/.test(strippedValue); // Ensure it has 12 digits
            }
        ),
});
