import React, { useRef, useState } from "react";
import {
    sendInAppKycOtpApi,
    verifyInAppKycOtpApi,
} from "../../../apis/coApplicant";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { isJsonString } from "../../../utils/misc";
import {
    PAN_AND_AADHAR_ERROR_MESSAGE,
    VERIFY_OTP_ERROR_MESSAGE,
} from "../constants";

const useVerifyOtp = ({ aadharNo, token }: { aadharNo: any; token: any }) => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: { otp: any[] }) => {
        try {
            setLoading(true);
            const otpCode = values.otp.join("");

            const response = await verifyInAppKycOtpApi({
                aadhaarNo: aadharNo?.split(" ")?.join(""),
                otp: otpCode,
            });

            if (response?.success) {
                if (!response?.data?.isPanAadhaarNameMatched) {
                    toast.error(PAN_AND_AADHAR_ERROR_MESSAGE);
                    navigate("/verify-details", {
                        state: { token: state?.token },
                    });
                    return;
                }
                navigate(`/verify-details`, {
                    state: { token: token },
                });
            }
        } catch (error: any) {
            if (isJsonString(error?.response?.data?.message)) {
                toast.error(VERIFY_OTP_ERROR_MESSAGE);
                navigate("/verify-details", { state: { token: state?.token } });
            } else {
                toast.error("Something went wrong!");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        try {
            setLoading(true);

            const response = await sendInAppKycOtpApi({
                aadhaarNo: aadharNo?.split(" ")?.join(""),
            });

            if (response?.success) {
                toast.success("OTP requested");
            }
        } catch (error: any) {
            if (isJsonString(error?.response?.data?.message)) {
                const errorObj = JSON.parse(error?.response?.data?.message);
                toast.error(errorObj?.message);
            } else {
                toast.error("Something went wrong!");
            }
        } finally {
            setLoading(false);
        }
    };

    return {
        handleSubmit,
        handleResendOtp,
        loading,
    };
};

export default useVerifyOtp;
