import React, { useRef } from "react";
import MainContainer from "../MainContainer";
import Header from "../../components/Header";
import {
    AADHAR_NUMBER_LABEL,
    DESC,
    HEADER,
    LOADING_TEXT,
    OTP_LABEL,
    VERIFY_OTP_TEXT,
    validationSchema,
} from "./constants";
import { useLocation, useNavigate } from "react-router";
import Button from "../../components/Button";
import OtpForm from "./components/Otp/Otp";
import ScreenLoader from "../../components/ScreenLoader";
import { IOtpFormik } from "./components/Otp/types";
import { Formik } from "formik";
import useVerifyOtp from "./hooks/useVerifyOtp";
import { ResendOtp } from "./components";

const AadharDetailsVerify = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { state } = location;

    const verifyAadharFormRef = useRef<any>(null);

    const { handleSubmit, handleResendOtp, loading } = useVerifyOtp({
        aadharNo: state?.aadharNumber,
        token: state?.token,
    });

    return (
        <MainContainer>
            {loading && <ScreenLoader message={LOADING_TEXT} />}
            <Formik
                initialValues={{ otp: Array(6).fill("") }}
                validationSchema={validationSchema}
                innerRef={(ref) => (verifyAadharFormRef.current = ref)}
                onSubmit={async (values) => {
                    await handleSubmit(values);
                }}
            >
                {(formikProps: IOtpFormik["formikProps"]) => (
                    <div className="p-4 flex flex-col relative w-full">
                        <div className="h-[calc(100vh-140px)] overflow-y-auto">
                            <Header
                                title={HEADER}
                                onBack={() => navigate("/aadhar-details")}
                            />
                            <div className={`flex flex-col items-center`}>
                                <span className="text-neutral-3 text-left mt-4">
                                    {DESC}
                                </span>
                            </div>
                            <div className={`mt-7`}>
                                <div
                                    className={`text-[#848484] uppercase text-[0.75rem] ml-2 mb-1`}
                                >
                                    {AADHAR_NUMBER_LABEL}
                                </div>
                                <div
                                    className={`bg-[#F5F5F5] border-[0.05rem] border-[#E9E9E9] rounded-[1rem] p-[1rem]`}
                                >
                                    {state?.aadharNumber}
                                </div>
                            </div>
                            <div className={`mt-4`}>
                                <div
                                    className={`text-[#848484] uppercase text-[0.75rem] ml-2 mb-1`}
                                >
                                    {OTP_LABEL}
                                </div>
                                <OtpForm formikProps={formikProps} />
                            </div>
                            <ResendOtp
                                onResend={async () => handleResendOtp()}
                            />
                        </div>
                        <div
                            className={
                                "fixed bottom-0 bg-white w-full gap-x-2 p-3 max-w-3xl mx-auto left-1/2 translate-x-[-50%] cursor-pointer"
                            }
                        >
                            <Button
                                text={VERIFY_OTP_TEXT}
                                type="primary"
                                onClick={async () => {
                                    formikProps.handleSubmit();
                                }}
                                disabled={
                                    !formikProps?.values?.otp?.every(
                                        (item) => item
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </MainContainer>
    );
};

export default AadharDetailsVerify;
